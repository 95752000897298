import React, { useEffect, useRef, useContext } from "react";
import { Box, Paper, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { ProctorContext } from "./ProctorContext";
import { toast } from "react-toastify";

const FloatingVideo = ({ onClose }) => {
  const videoRef = useRef(null);
  const { stream } = useContext(ProctorContext);
  const retryCountRef = useRef(0);
  const maxRetries = 3;

  useEffect(() => {
    let mounted = true;
    let retryTimeout;

    const setupVideo = async () => {
      try {
        if (!videoRef.current || !mounted) return;

        const currentStream = stream || window.proctorStream;
        if (!currentStream) {
          if (retryCountRef.current < maxRetries) {
            retryCountRef.current++;
            retryTimeout = setTimeout(setupVideo, 1000);
            return;
          }
          console.error("No video stream available after retries");
          toast.error("Camera stream not available");
          return;
        }

        videoRef.current.srcObject = currentStream;
        await videoRef.current.play();
        console.log("Video stream started successfully");
        retryCountRef.current = 0;
      } catch (err) {
        console.error("Error setting up video:", err);
        if (retryCountRef.current < maxRetries) {
          retryCountRef.current++;
          retryTimeout = setTimeout(setupVideo, 1000);
        } else {
          toast.error("Failed to start camera stream");
        }
      }
    };

    setupVideo();

    return () => {
      mounted = false;
      clearTimeout(retryTimeout);
      if (videoRef.current) {
        videoRef.current.srcObject = null;
      }
    };
  }, [stream]);

  return (
    <Paper
      elevation={3}
      sx={{
        position: "fixed",
        top: 20,
        left: 20,
        width: 200,
        height: 150,
        zIndex: 99999,
        borderRadius: 2,
        overflow: "hidden",
        backgroundColor: "#000",
        boxShadow: 3,
        "&:hover": {
          boxShadow: 6,
        },
      }}
    >
      <Box sx={{ position: "relative", height: "100%" }}>
        <IconButton
          size="small"
          sx={{
            position: "absolute",
            right: 4,
            top: 4,
            backgroundColor: "rgba(0,0,0,0.5)",
            "&:hover": { backgroundColor: "rgba(0,0,0,0.7)" },
            color: "white",
            zIndex: 2,
          }}
          onClick={onClose}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
        <video
          ref={videoRef}
          autoPlay
          playsInline
          muted
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
            transform: "scaleX(-1)",
          }}
        />
      </Box>
    </Paper>
  );
};

export default FloatingVideo;