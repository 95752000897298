import React, { createContext, useState, useCallback, useRef, useEffect } from 'react';
import axios from 'axios';
import io from 'socket.io-client';

export const ProctorContext = createContext({
  isProctoring: false,
  startProctoring: () => {},
  stopProctoring: () => {},
  stream: null,
  showVideo: false,
  setShowVideo: () => {},
  peerConnection: null
});

const RECONNECTION_ATTEMPTS = 3;
const RECONNECTION_DELAY = 2000;

export const ProctorProvider = ({ children }) => {
  const reconnectAttemptsRef = useRef(0);
  const reconnectTimeoutRef = useRef(null);
  const screenStreamRef = useRef(null);
  const cameraStreamRef = useRef(null);
  const isReconnecting = useRef(false);

  const [isProctoring, setIsProctoring] = useState(false);
  const [stream, setStream] = useState(null);
  const socketRef = useRef(null);
  const peerConnectionRef = useRef(null);
  const [showVideo, setShowVideo] = useState(false);
  const BASE_URL = import.meta.env.VITE_BASE_URL;

  useEffect(() => {
    // Cleanup function
    return () => {
      if (reconnectTimeoutRef.current) {
        clearTimeout(reconnectTimeoutRef.current);
      }
      if (socketRef.current) {
        socketRef.current.disconnect();
      }
      if (peerConnectionRef.current) {
        peerConnectionRef.current.close();
      }
    };
  }, []);


  const stopAllTracks = () => {
    if (cameraStreamRef.current) {
      cameraStreamRef.current.getTracks().forEach(track => track.stop());
    }
    if (screenStreamRef.current) {
      screenStreamRef.current.getTracks().forEach(track => track.stop());
    }
  };

  const getScreenShare = async () => {
    try {
      // Only request screen share if we don't already have it
      if (!screenStreamRef.current || screenStreamRef.current.getTracks().every(track => track.readyState === 'ended')) {
        const screenStream = await navigator.mediaDevices.getDisplayMedia({
          video: {
            cursor: 'always',
            displaySurface: 'monitor'
          },
          audio: false
        });
        
        screenStreamRef.current = screenStream;

        // Handle screen sharing stop
        screenStream.getVideoTracks()[0].addEventListener('ended', async () => {
          if (!isReconnecting.current) {
            await handleScreenShareEnd();
          }
        });

        return screenStream;
      }
      return screenStreamRef.current;
    } catch (error) {
      console.error('Error getting screen share:', error);
      throw error;
    }
  };

  const handleScreenShareEnd = async () => {
    try {
      // Only request new screen share if not reconnecting
      if (!isReconnecting.current) {
        const newScreenStream = await getScreenShare();
        
        if (peerConnectionRef.current && newScreenStream) {
          const [newTrack] = newScreenStream.getVideoTracks();
          
          const screenSender = peerConnectionRef.current.getSenders()
            .find(sender => sender.track?.kind === 'video' && sender.track.label.includes('screen'));
            
          if (screenSender) {
            await screenSender.replaceTrack(newTrack);
          }
        }
      }
    } catch (error) {
      console.error('Error handling screen share end:', error);
    }
  };

  const initializeSocket = useCallback((testId, participantId) => {
    try {
      // Clean up existing socket if any
      if (socketRef.current) {
        socketRef.current.disconnect();
        socketRef.current = null;
      }

      // Create new socket connection
      const socket = io(BASE_URL, {
        transports: ['websocket'],
        query: { testId, participantId, role: 'candidate' },
        reconnection: true,
        reconnectionAttempts: RECONNECTION_ATTEMPTS,
        reconnectionDelay: RECONNECTION_DELAY,
        timeout: 10000
      });

      // Verify socket connection
      socket.on('connect', () => {
        console.log('Socket connected successfully');
      });

      socket.on('connect_error', (error) => {
        console.error('Socket connection error:', error);
        throw new Error('Failed to connect to server');
      });

      socketRef.current = socket;
      return socket;
    } catch (error) {
      console.error('Socket initialization error:', error);
      throw error;
    }
  }, [BASE_URL]);

  const initializeWebRTC = async (testId, participantId, email) => {
    try {
      isReconnecting.current = false;
      
      // Initialize socket first
      const socket = initializeSocket(testId, participantId);
      if (!socket) {
        throw new Error('Failed to initialize socket connection');
      }

      // Get camera stream
      if (!cameraStreamRef.current || cameraStreamRef.current.getTracks().every(track => track.readyState === 'ended')) {
        const cameraStream = await navigator.mediaDevices.getUserMedia({ 
          video: true,
          audio: true 
        });
        cameraStreamRef.current = cameraStream;
      }

      // Get screen stream
      const screenStream = await getScreenShare();
      
      // Combine streams
      const combinedStream = new MediaStream([
        ...cameraStreamRef.current.getTracks(),
        ...screenStream.getTracks()
      ]);
      
      setStream(combinedStream);
      window.proctorStream = combinedStream;

      // Initialize peer connection
      const pc = new RTCPeerConnection({
        iceServers: [
          { urls: 'stun:stun.l.google.com:19302' },
          { urls: 'stun:stun1.l.google.com:19302' },
          {
            urls: 'turn:numb.viagenie.ca',
            username: 'webrtc@live.com',
            credential: 'muazkh'
          }
        ],
        iceTransportPolicy: 'all',
        bundlePolicy: 'max-bundle',
        rtcpMuxPolicy: 'require',
        iceCandidatePoolSize: 1
      });

      peerConnectionRef.current = pc;

      // Add tracks to peer connection
      combinedStream.getTracks().forEach(track => {
        pc.addTrack(track, combinedStream);
      });

      // Set up WebRTC event handlers
      pc.onicecandidate = (event) => {
        if (event.candidate && socketRef.current) {
          socketRef.current.emit('ice-candidate', {
            candidateId: participantId,
            candidate: event.candidate
          });
        }
      };

      pc.onconnectionstatechange = () => {
        console.log('Connection state:', pc.connectionState);
        if (pc.connectionState === 'failed' || pc.connectionState === 'disconnected') {
          handleReconnection(testId, participantId);
        }
      };

      // Set up socket event handlers
      socket.on('answer', async ({ sdp }) => {
        try {
          if (pc.signalingState !== "closed") {
            await pc.setRemoteDescription(new RTCSessionDescription(sdp));
          }
        } catch (error) {
          console.error('Error setting remote description:', error);
        }
      });

      socket.on('ice-candidate', async ({ candidate }) => {
        try {
          if (pc.signalingState !== "closed") {
            await pc.addIceCandidate(new RTCIceCandidate(candidate));
          }
        } catch (error) {
          console.error('Error adding ICE candidate:', error);
        }
      });

      // Create and send offer
      const offer = await pc.createOffer();
      await pc.setLocalDescription(offer);
      
      socket.emit('offer', {
        testId,
        candidateId: participantId,
        sdp: offer
      });

      return pc;
    } catch (error) {
      console.error('Error initializing WebRTC:', error);
      // Cleanup on error
      stopAllTracks();
      if (socketRef.current) {
        socketRef.current.disconnect();
        socketRef.current = null;
      }
      if (peerConnectionRef.current) {
        peerConnectionRef.current.close();
        peerConnectionRef.current = null;
      }
      throw error;
    }
  };

  const handleReconnection = useCallback(async (testId, participantId) => {
    if (reconnectAttemptsRef.current >= RECONNECTION_ATTEMPTS) {
      console.log('Max reconnection attempts reached');
      return;
    }

    try {
      isReconnecting.current = true;
      reconnectAttemptsRef.current++;
      console.log(`Attempting reconnection (${reconnectAttemptsRef.current}/${RECONNECTION_ATTEMPTS})`);

      // Reuse existing streams if possible
      const pc = await initializeWebRTC(testId, participantId);
      
      // Create and send new offer
      const offer = await pc.createOffer();
      await pc.setLocalDescription(offer);
      
      socketRef.current?.emit('offer', {
        testId,
        candidateId: participantId,
        sdp: offer
      });

      // Reset reconnection attempts on successful connection
      reconnectAttemptsRef.current = 0;
      isReconnecting.current = false;
    } catch (error) {
      console.error('Reconnection attempt failed:', error);
      isReconnecting.current = false;
      
      if (reconnectAttemptsRef.current < RECONNECTION_ATTEMPTS) {
        reconnectTimeoutRef.current = setTimeout(() => {
          handleReconnection(testId, participantId);
        }, RECONNECTION_DELAY);
      }
    }
  }, []);

  const startProctoring = useCallback(async (testId) => {
    try {
      stopAllTracks(); // Clean up any existing tracks
      
      const testInfo = JSON.parse(localStorage.getItem('testInfo'));
      if (!testInfo) {
        throw new Error('Test information not found');
      }

      // Start proctoring session
      const response = await axios.post(`${BASE_URL}/proctor/start-session`, {
        testId: testId.toString(),
        participantId: testInfo.participantId,
        email: localStorage.getItem('email')
      });

      if (!response.data.success) {
        throw new Error(response.data.error || 'Failed to start proctoring session');
      }

      // Initialize WebRTC after successful session creation
      await initializeWebRTC(testId, testInfo.participantId);

      setIsProctoring(true);
      setShowVideo(true);
      localStorage.setItem('proctoringActive', 'true');
      localStorage.setItem('showProctorVideo', 'true');
      
    } catch (error) {
      console.error('Error starting proctoring:', error);
      stopAllTracks();
      throw error;
    }
  }, []);

  const stopProctoring = useCallback(async (testId) => {
    try {
      const testInfo = JSON.parse(localStorage.getItem('testInfo'));
      
      await axios.post(`${BASE_URL}/proctor/end-session`, {
        testId: testId,
        participantId: testInfo.participantId
      });

      stopAllTracks();
      
      if (socketRef.current) {
        socketRef.current.disconnect();
      }
      if (peerConnectionRef.current) {
        peerConnectionRef.current.close();
      }

      setIsProctoring(false);
      setShowVideo(false);
      localStorage.removeItem('proctoringActive');
      localStorage.removeItem('showProctorVideo');
    } catch (error) {
      console.error('Error stopping proctoring:', error);
      throw error;
    }
  }, []);

  // Cleanup on unmount
  useEffect(() => {
    return () => {
      stopAllTracks();
      if (reconnectTimeoutRef.current) {
        clearTimeout(reconnectTimeoutRef.current);
      }
      if (socketRef.current) {
        socketRef.current.disconnect();
      }
      if (peerConnectionRef.current) {
        peerConnectionRef.current.close();
      }
    };
  }, []);

  return (
    <ProctorContext.Provider value={{ 
      isProctoring, 
      startProctoring, 
      stopProctoring,
      stream,
      showVideo,
      setShowVideo,
      peerConnection: peerConnectionRef.current
    }}>
      {children}
    </ProctorContext.Provider>
  );
};