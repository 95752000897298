import React, { useContext, useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { ProctorContext } from './ProctorContext';
import FloatingVideo from './FloatingVideo';

const ProctorVideoWrapper = () => {
  const context = useContext(ProctorContext);
  const [retryCount, setRetryCount] = useState(0);
  const maxRetries = 3;

  useEffect(() => {
    if (!context.isProctoring && retryCount < maxRetries) {
      const timer = setTimeout(() => {
        setRetryCount(prev => prev + 1);
        const testInfo = JSON.parse(localStorage.getItem('testInfo'));
        if (testInfo) {
          context.startProctoring(testInfo.testId)
            .catch(error => {
              console.error('Error restarting proctor:', error);
              toast.error('Failed to restart proctoring');
            });
        }
      }, 5000);

      return () => clearTimeout(timer);
    }
  }, [context.isProctoring, retryCount]);

  if (!context || !context.isProctoring || !context.showVideo) {
    return null;
  }

  return <FloatingVideo onClose={() => context.setShowVideo(false)} />;
};

export default ProctorVideoWrapper;