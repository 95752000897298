import React from "react";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";

const QuestionNavigation = ({
  questions,
  currentQuestion,
  onQuestionSelect,
  isOpen,
  onToggle,
  answeredQuestions = {},
  disabled = false,
}) => {
  const totalQuestions = questions.length;
  const answeredCount = Object.keys(answeredQuestions).length;

  const handleQuestionClick = (type, globalIndex) => {
    if (disabled) return;
    onQuestionSelect({ type, index: globalIndex });
  };

  // Generate a unique key for each question
  const getQuestionKey = (question, index) => {
    const baseId = question.mcq_question_id || question.question_id;
    const type = question.mcq_question_id ? 'mcq' : 'coding';
    return `${type}-${baseId}-${index}`;
  };

  return (
    <div
      className={`fixed right-0 top-[64px] h-[calc(100vh-64px)] bg-white shadow-lg transition-all duration-300 transform ${
        isOpen ? "translate-x-0" : "translate-x-full"
      } ${disabled ? "opacity-50 pointer-events-none" : ""}`}
      style={{
        width: "300px",
        zIndex: 1000,
      }}
    >
      <button
        onClick={onToggle}
        className="absolute -left-10 top-1/2 bg-blue-600 text-white p-2 rounded-l-lg"
      >
        {isOpen ? <FaChevronRight /> : <FaChevronLeft />}
      </button>

      <div className="p-6 overflow-y-auto h-full">
        {/* Progress Summary */}
        <div className="mb-6 bg-gray-50 p-4 rounded-lg border border-gray-200">
          <h3 className="text-lg font-semibold mb-2">Progress</h3>
          <div className="flex justify-between items-center mb-2">
            <span>Completed:</span>
            <span className="font-medium">
              {answeredCount}/{totalQuestions}
            </span>
          </div>
          <div className="w-full bg-gray-200 rounded-full h-2.5">
            <div
              className="bg-green-600 h-2.5 rounded-full transition-all duration-300"
              style={{ width: `${(answeredCount / totalQuestions) * 100}%` }}
            ></div>
          </div>
          <p className="text-sm text-gray-600 mt-2">
            {totalQuestions - answeredCount} questions remaining
          </p>
        </div>

        <h3 className="text-lg font-semibold mb-4">Questions</h3>
        <div className="grid grid-cols-3 gap-3">
          {questions.map((q, idx) => {
            const questionId = q.mcq_question_id || q.question_id;
            const isAnswered = answeredQuestions[questionId];
            const isCurrent = currentQuestion.index === idx;

            return (
              <button
                key={getQuestionKey(q, idx)}
                onClick={() =>
                  handleQuestionClick(q.mcq_question_id ? "mcq" : "coding", idx)
                }
                className={`
                  h-12 w-12 flex items-center justify-center rounded-lg
                  text-base font-medium transition-all duration-200
                  ${isCurrent ? "ring-2 ring-blue-500 bg-blue-100" : ""}
                  ${
                    isAnswered
                      ? "bg-green-100 border-2 border-green-600 text-green-700"
                      : "bg-gray-100 border border-gray-300 hover:bg-gray-200 text-gray-700"
                  }
                  ${disabled ? "cursor-not-allowed" : "cursor-pointer"}
                `}
                disabled={disabled}
                title={`Question ${idx + 1} (${
                  q.mcq_question_id ? "MCQ" : "Coding"
                })`}
              >
                {idx + 1}
              </button>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default QuestionNavigation;